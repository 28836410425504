<div style="padding-bottom:5%">
   <div class="row">
      <div class="col-12">
         <h1>{{ "PROJECT_DETAILS.PROJECT_DETAILS" | translate }}</h1>
      </div>
   </div>
   <div class="row">
      <div class="col-12">&nbsp;</div>
   </div>
   <div class="row">
      <div class="col-sm-8 col-md-6">
         <p class="title-label">{{ "PROJECT_DETAILS.CREDITING_PERIOD" | translate }}</p>
         <p class="value-label">
            <span *ngIf="project_detail?.crediting_period_start">{{ project_detail && project_detail.crediting_period_start * 1000  | date }}</span>
            <span *ngIf="project_detail?.crediting_period_end">&nbsp;-&nbsp;</span>
            <span *ngIf="project_detail?.crediting_period_end">{{ project_detail && project_detail.crediting_period_end * 1000  | date }}</span>
         </p>
      </div>
      <div class="col-sm-4 col-md-6">
         <p class="title-label">{{ "PROJECT_DETAILS.SCALE" | translate }}</p>
         <p class="value-label">{{ project_detail?.lu_project_scale?.data | titlecase }} Scale</p>
      </div>
   </div>

   <div class="row">
      <div class="col-12">&nbsp;</div>
   </div>

   <div class="row">
      <div class="col-12">
         <p class="title-label">{{ "PROJECT_DETAILS.VERIFICATION" | translate }}</p>
         <p class="value-label"><a [href]="project_detail?.verification_url" target="_blank">{{ "PROJECT_DETAILS.LINK" | translate }} ></a></p>
      </div>
   </div>

   <hr>
   <div class="row">
      <div class="col-12 map" #map>
      </div>
   </div>
   <hr *ngIf = "project_detail?.geolocation">

   <div class="row">
      <div class="col-12">&nbsp;</div>
   </div>

   <div class="row">
      <div class="col-12">
         <h1>{{ "PROJECT_DETAILS.AUDITOR" | translate }}</h1>
      </div>
      <div class="col-12">
         <p class="title-label">{{ project_detail?.project_auditor?.project_auditor_name   }}</p>
         <p class="value-label">{{ project_detail?.project_auditor?.project_auditor_description }}</p>
      </div>
   </div>

   <div class="row">
      <div class="col-12">&nbsp;</div>
   </div>

   <div class="row">
      <div class="col-12">
         <h1>{{ "PROJECT_DETAILS.DEVELOPER" | translate }}</h1>
      </div>
      <div class="col-12">
         <p class="title-label">{{ project_detail?.project_developer?.project_developer_name   }}</p>
         <p class="value-label">{{ project_detail?.project_developer?.project_developer_description   }}</p>
      </div>
   </div>
</div>

