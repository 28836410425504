<gs-carousel></gs-carousel>
<div class="header">
  <a class="simplizero-logo" href="/">
    <img
      class="logo"
      src="./assets/simplizero-logo/SimpliZero_white.png"
      alt="simplizero logo"
    />
  </a>
  <div class="title text-0 bold">{{ project?.name }}</div>
  <div class="type text-2 regular mobile">
    {{ "PROJECT_TYPES." + project?.lu_project_type?.data | translate | uppercase }} PROJECT
  </div>
  <gs-handle-lightbox-scroll-click
    #learnmore
    lightbox="true"
    lightboxtag="gs-video-lightbox"
    class="learn-more mobile c-pointer"
    *ngIf="project?.video"
  >
    <div class="text bold text-3">{{ "PROJECT_V2.LEARN_MORE" | translate }}</div>
    <img src="./assets/project-v2/header/play.svg" alt="play button opens video lightbox" />
  </gs-handle-lightbox-scroll-click>
  <div class="type-learn-more">
    <div class="type text-2 regular">
      {{ "PROJECT_TYPES." + project?.lu_project_type?.data | translate | uppercase }} {{"PROJECT_V2.PROJECT" | translate | uppercase}}
    </div>
    <gs-handle-lightbox-scroll-click
        #learnmore
        lightbox="true"
        lightboxtag="gs-video-lightbox"
        class="learn-more c-pointer"
        *ngIf="project?.video"
        inputforlightbox = '{"videosource": "{{project?.video}}", "id": "0"}'
    >
      <div class="text bold text-3">{{ "PROJECT_V2.LEARN_MORE" | translate }}</div>
      <img src="./assets/project-v2/header/play.svg" alt="play button opens video lightbox" />
    </gs-handle-lightbox-scroll-click>
  </div>
  <div class="location">
    <img src="./assets/project-v2/header/location.png" alt="location pin icon" />
    <div class="regular text-3">{{ project?.location?.country }}</div>
  </div>
</div>
<app-tabs class="mobile"></app-tabs>
<div class="gs-project mobile">
  <gs-offset-supporter></gs-offset-supporter>
  <div class="about" id="about">
    <div class="title text-1 bold">{{ "PROJECT_V2.ABOUT_PROJECT" | translate }}</div>
    <div
      class="description text-3"
      [innerHTML]="project?.overview?.about_project"
    ></div>
  </div>
  <div class="verifier-sdg">
    <div class="verifier">
      <div class="title text-1 bold">{{ "PROJECT_V2.VERIFIER" | translate }}
      </div>
      <div class="logo">
        <img
          [src]="project?.project_verifier?.project_verification_logo?.url"
          [alt]="carbonVerifierAltText"
        />
        <div>{{ project?.project_verifier?.project_verifier_name }}</div>
      </div>
    </div>
    <div class="verification">
      <div class="title bold text-1">{{ "PROJECT_V2.PROJ_VERIFICATION" | translate }}
        <gs-tooltip data-toggle="tooltip" [title]='project?.project_verifier?.project_verification_description'>
          <img
            src='./assets/project-v2/tooltip.png'
            alt="tooltip icon"
            class="c-pointer"
            style="height: 20px;margin: 2.5px 8px;"
          />
        </gs-tooltip>
      </div>
      <button class="view text-3 c-pointer">
        <a
          [href]="project?.verification_url"
          target="_blank"
          rel="noopener noreferrer"
        >
        {{ "PROJECT_V2.VIEW" | translate }}
        </a>
      </button>
    </div>
    <div class="sdgs">
      <div class="title bold text-1">
        {{ "PROJECT_V2.SDG_TITLE" | translate }}
        <gs-tooltip data-toggle="tooltip" [title]='"PROJECT_V2.SDG_TOOLTIP" | translate'>
          <img
            src='./assets/project-v2/tooltip.png'
            alt="tooltip icon"
            class="c-pointer"
            style="height: 20px;margin: 2.5px 8px;"
          />
        </gs-tooltip>
      </div>
      <div class="sdg" *ngFor="let sdg of project?.sdg_icons">
        <img [src]="sdg?.image_url?.url" [alt]="sdg?.image_url?.alt_text || 'sdg icon'" />
        <div class="description">{{ "PROJECT_V2.UN_SDGS." + sdg?.id | translate }}</div>
      </div>
    </div>
  </div>
  <div class="at-glance">
    <div class="title bold text-1">{{ "PROJECT_V2.AT_GLANCE" | translate }}</div>
    <div class="description" [innerHTML]="project?.overview?.at_a_glance"></div>
  </div>
  <div class="benefits" id="benefits">
    <div class="title bold text-1">{{ "PROJECT_V2.PROJECT_BENEFITS" | translate }}</div>
    <div
      class="description"
      [innerHTML]="project?.overview?.benefits_environmental"
    ></div>
    <div
      class="description"
      [innerHTML]="project?.overview?.benefits_social"
    ></div>
    <div class="type blue">
      <div class="title bold text-1">
        {{ "PROJECT_V2.ABOUT" | translate }} {{ project?.lu_project_type?.data }}
      </div>
      <div class="description text-3">
        {{ "PROJECT_TYPES_DESCRIPTION." + project?.lu_project_type?.data | translate }}
      </div>
    </div>
  </div>
  <div class="how-it-works">
    <div class="title bold text-1">{{ "PROJECT_V2.HOW_IT_WORKS" | translate }}</div>
    <div
      class="description"
      [innerHTML]="project?.overview?.how_it_works"
    ></div>
    <div class="accounting blue" id="registry">
      <div class="title bold text-1">{{ "PROJECT_V2.ACCOUNTING" | translate }}</div>
      <div class="description text-3">
        {{"PROJECT_V2.ACCOUNTING_DESC" | translate}}
      </div>
      <a class="view" [href]="carbonRegistryUrl" target="_blank" rel="noopener noreferrer">
        <div class="text text-2">{{ "PROJECT_V2.VIEW" | translate }}</div>
        <img src="/assets/project-v2/accounting/arrow-right-black.svg" alt="arrow opens carbon accounting" />
      </a>
    </div>
  </div>
  <div class="grey">
    <div class="crediting-period">
      <div class="title bold text-1">{{ "PROJECT_V2.CREDITING_PERIOD" | translate }}</div>
      <div class="description text-2">
        {{ project && project.crediting_period_start * 1000 | date }} -
        {{ project && project.crediting_period_end * 1000 | date }}
      </div>
    </div>
    <div class="auditor">
      <div class="title bold text-1">{{ "PROJECT_V2.AUDITOR" | translate }}
      </div>
      <div class="description text-2" style="display: flex;align-items: center;">
        <div class="text">
          {{ project?.project_auditor?.project_auditor_name }}
        </div>
        <gs-tooltip data-toggle="tooltip" [title]='project?.project_auditor?.project_auditor_description'>
          <img
            src='./assets/project-v2/tooltip.png'
            alt="tooltip icon"
            class="c-pointer"
            style="height: 20px;margin: 2.5px 8px;"
          />
        </gs-tooltip>
      </div>
    </div>
    <div class="developer">
      <div class="title bold text-1">{{ "PROJECT_V2.DEVELOPER" | translate }}</div>
      <div class="description text-2" style="display: flex;align-items: center;">
        <div class="text">
          {{ project?.project_developer?.project_developer_name }}
        </div>
        <gs-tooltip data-toggle="tooltip" [title]='project?.project_developer?.project_developer_description'>
          <img
            src='./assets/project-v2/tooltip.png'
            alt="tooltip icon"
            class="c-pointer"
            style="height: 20px;margin: 2.5px 8px;"
          />
        </gs-tooltip>
      </div>
    </div>
    <div class="location">
      <div class="title bold text-1">{{ "PROJECT_V2.LOCATION" | translate }}</div>
      <div class="map" #mapMobile></div>
    </div>
  </div>
</div>
<div class="gs-project">
  <div class="left">
    <div class="about">
      <div class="title text-1 bold">{{ "PROJECT_V2.ABOUT_PROJECT" | translate }}</div>
      <div
        class="description text-3"
        [innerHTML]="project?.overview?.about_project"
      ></div>
    </div>
    <div class="at-glance">
      <div class="title bold text-1">{{ "PROJECT_V2.AT_GLANCE" | translate }}</div>
      <div
        class="description"
        [innerHTML]="project?.overview?.at_a_glance"
      ></div>
    </div>
    <div class="benefits">
      <div class="title bold text-1">{{ "PROJECT_V2.PROJECT_BENEFITS" | translate }}</div>
      <div
        class="description"
        [innerHTML]="project?.overview?.benefits_environmental"
      ></div>
      <div
        class="description"
        [innerHTML]="project?.overview?.benefits_social"
      ></div>
      <div class="type blue">
        <div class="title bold text-1">
          {{ "PROJECT_V2.ABOUT" | translate }} {{ project?.lu_project_type?.data }}
        </div>
        <div class="description text-3">
          {{ "PROJECT_TYPES_DESCRIPTION." + project?.lu_project_type?.data | translate }}
        </div>
      </div>
    </div>
    <div class="how-it-works">
      <div class="title bold text-1">{{ "PROJECT_V2.HOW_IT_WORKS" | translate }}</div>
      <div
        class="description"
        [innerHTML]="project?.overview?.how_it_works"
      ></div>
      <div class="accounting blue">
        <div class="title bold text-1">{{ "PROJECT_V2.ACCOUNTING" | translate }}</div>
        <div class="description text-3">
          {{"PROJECT_V2.ACCOUNTING_DESC" | translate}}
        </div>
        <div class="view c-pointer">
          <div class="text text-2 bold">
            <a id="project-carbon-view-button" [href]="carbonRegistryUrl" target="_blank" rel="noopener noreferrer">
              {{ "PROJECT_V2.VIEW" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right">
    <gs-offset-supporter></gs-offset-supporter>
    <div class="verifier-sdg">
      <div class="verifier">
        <div class="title text-1 bold">{{ "PROJECT_V2.VERIFIER" | translate }}</div>
        <div class="logo">
          <img
            [src]="project?.project_verifier?.project_verification_logo?.url"
            [alt]="carbonVerifierAltText"
          />
          <div>{{ project?.project_verifier?.project_verifier_name }}</div>
        </div>
      </div>
      <div class="verification">
        <div class="title bold text-1">{{ "PROJECT_V2.PROJ_VERIFICATION" | translate }}
          <gs-tooltip data-toggle="tooltip" [title]='project?.project_verifier?.project_verification_description'>
            <img
              src='./assets/project-v2/tooltip.png'
              alt="tooltip icon"
              class="c-pointer"
              style="height: 20px;margin: 2.5px 8px;"
            />
          </gs-tooltip>
        </div>
        <button class="view text-3 c-pointer bold">
          <a
            [href]="project?.verification_url"
            target="_blank"
            rel="noopener noreferrer"
          >
          {{ "PROJECT_V2.VIEW" | translate }}
          </a>
        </button>
      </div>
      <div class="sdgs">
        <div class="title bold text-1">{{ "PROJECT_V2.SDG_TITLE" | translate }}
          <gs-tooltip data-toggle="tooltip" [title]='"PROJECT_V2.SDG_TOOLTIP" | translate'>
          <img
            src='./assets/project-v2/tooltip.png'
            alt="tooltip icon"
            class="c-pointer"
            style="height: 20px;margin: 2.5px 8px;"
          />
        </gs-tooltip>
        </div>
        <div class="sdg" *ngFor="let sdg of project?.sdg_icons">
          <img [src]="sdg?.image_url?.url" [alt]="sdg?.image_url?.alt_text || 'sdg icon'" />
          <div class="description">
            {{ "PROJECT_V2.UN_SDGS." + sdg?.id | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="grey">
      <div class="crediting-period">
        <div class="title bold text-1">{{ "PROJECT_V2.CREDITING_PERIOD" | translate }}</div>
        <div class="description text-2">
          {{ project && project.crediting_period_start * 1000 | date }} -
          {{ project && project.crediting_period_end * 1000 | date }}
        </div>
      </div>
      <div class="auditor">
        <div class="title bold text-1">{{ "PROJECT_V2.AUDITOR" | translate }}</div>
        <div class="description text-2" style="display: flex;align-items: center;">
          <div class="text">
            {{ project?.project_auditor?.project_auditor_name }}
          </div>
          <gs-tooltip data-toggle="tooltip" [title]='project?.project_auditor?.project_auditor_description'>
            <img
              src='./assets/project-v2/tooltip.png'
              alt="tooltip icon"
              class="c-pointer"
              style="height: 20px;margin: 2.5px 8px;"
            />
          </gs-tooltip>
        </div>
      </div>
      <div class="developer">
        <div class="title bold text-1">{{ "PROJECT_V2.DEVELOPER" | translate }}</div>
        <div class="description text-2" style="display: flex;align-items: center;">
          <div class="text">
            {{ project?.project_developer?.project_developer_name }}
          </div>
          <gs-tooltip data-toggle="tooltip" [title]='project?.project_developer?.project_developer_description'>
            <img
              src='./assets/project-v2/tooltip.png'
              alt="tooltip icon"
              class="c-pointer"
              style="height: 20px;margin: 2.5px 8px;"
            />
          </gs-tooltip>
        </div>
      </div>
      <div class="location">
        <div class="title bold text-1">{{ "PROJECT_V2.LOCATION" | translate }}</div>
        <div class="map" #mapDesktop></div>
      </div>
    </div>
  </div>
</div>
<app-project-footer></app-project-footer>
