<app-project-header></app-project-header>

<div class="main">
  <div class="summary">{{summaryApiUrl}}</div>
  <app-project-banner></app-project-banner>
  <div class="tab-container">
    <div class="tabs">
      <ul>
        <li
          *ngFor="let tab of tabs; let i = index"
          [ngClass]="{ active: tab.isActive }"
        >
          {{ tab.name | translate }}
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="tab-wrap" [ngClass]="{ active: tabs[0].isActive }">
        <app-project-overview></app-project-overview>
      </div>
      <div class="tab-wrap" [ngClass]="{ active: tabs[1].isActive }">
        <app-project-updates></app-project-updates>
      </div>
      <div class="tab-wrap" [ngClass]="{ active: tabs[2].isActive }">
        <app-project-details></app-project-details>
      </div>
      <div class="tab-wrap" [ngClass]="{ active: tabs[3].isActive }">
        <app-project-registry></app-project-registry>
      </div>
    </div>
  </div>
</div>

<app-project-footer></app-project-footer>
