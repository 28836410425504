<div class="container-fluid" style="padding-bottom: 5%;">
  <div class="row">
    <div class="col-12">
      <h1>{{ "REGISTRY.CARBON_REGISTRY" | translate }}</h1>
      <p>
        To ensure transparency, Green Story Carbon Registry tracks issuance and retirement of carbon credits for all projects on the Green Story offset platform.
      </p>
       <a target="_blank" [href]="registryUrl">{{ "REGISTRY.VIEW" | translate }}</a>
    </div>
  </div>
</div>
