import { Component, OnInit, ViewChild } from "@angular/core";
import { ProjectService } from "../services/project.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.scss"],
})
export class ProjectDetailsComponent implements OnInit {
  project_detail: any;
  projectLocations;

  @ViewChild("map") mapElement: any;
  constructor(
    private projectService: ProjectService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.projectService.getProjectFromParent.subscribe((project) => {
      this.project_detail = project;
      if (this.project_detail && this.project_detail.geolocation) {
        const { lat, long } = this.project_detail.geolocation;
        const googleMap = document.createElement("gs-google-map");
        googleMap.setAttribute("zoom", "7");
        this.projectLocations = JSON.stringify([
          {
            lat: parseFloat(lat),
            lng: parseFloat(long),
          },
        ]);
        googleMap.setAttribute("projectlocations", this.projectLocations);
        this.mapElement.nativeElement.appendChild(googleMap);
      }
    });
  }
}
