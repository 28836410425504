import { Component, OnInit } from "@angular/core";
import { ProjectService } from "../services/project.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
})
export class ProjectComponent implements OnInit {
  project: any = {};

  summaryApiUrl:string;

  lanCode: string;

  tabs = [
    {
      name: "TABS.OVERVIEW",
      isActive: true,
    },
    {
      name: "TABS.UPDATES",
      isActive: false,
    },
    {
      name: "TABS.DETAILS",
      isActive: false,
    },
    {
      name: "TABS.REGISTRY",
      isActive: false,
    },
  ];

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    let projectId: string;
    projectId = this.route.snapshot.queryParamMap.get("projectId");
    if (!projectId) {
      projectId = this.route.snapshot.paramMap.get("projectId");
    }
    this.setLangCode();
    this.getProject(projectId);
    this.summaryApiUrl = `${environment.OFFSET_API_URL}/offset/summary/?filter_by_offset_project=${projectId}&measures=offset_value,supporter`;
  }

  setLangCode() {
    this.lanCode = this.route.snapshot.queryParamMap.get("lanCode");
    if (this.lanCode) {
      this.translate.use(this.lanCode);
    }
  }

  async getProject(projectId) {
    if (projectId) {
      await Promise.all([
        this.projectService.getProject(projectId, this.lanCode).subscribe((project) => {
          this.project = project;
          this.projectService.sendProject(this.project);
        }),
        this.projectService.getProjectSummary(projectId).subscribe(summary => {
          this.projectService.sendProjectSummary(summary);
        })
      ])

    }
  }

  // tabClicked(index) {
  //   this.tabs = this.tabs.map((tab, i) => {
  //     tab = { ...tab, isActive: i === index ? true : false };
  //     return tab;
  //   });
  // }
}
