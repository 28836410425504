
<div class="footer-mobile footer">
   <iframe width="100%" height="400" src="https://1036b60c.sibforms.com/serve/MUIEANKiBrkB60kA0S-7rl7npyOpB6vnu6CnV6AjK9XTrpPQpMt_rrDwzGCWVxvinypDgezcx8Px7w3ezHbODxkqa01TzxhX6fUrnuIxenoW7H_OwG6LyIZ6hWNEI-28eVX7I8RdbmcMEMpwuoT2ecYT8Gf9DVdV2n02CoFLswwu29c4k6M89k-EkfaFoxqoFxJRafHnrWPjyhSW" frameborder="0" allowfullscreen style="display: block; margin-left: 0; margin-right: auto; max-width: 100%;"></iframe>
 </div>
 
 <div class="footer row">
   <div class="section-1 footer-desktop col-4">
     <img class="logo" alt="simplizero logo" src="./assets/simplizero-logo/SimpliZero_white.png">
     <div class="xl:-mt-24">
       <iframe width="350" height="400" src="https://1036b60c.sibforms.com/serve/MUIEANKiBrkB60kA0S-7rl7npyOpB6vnu6CnV6AjK9XTrpPQpMt_rrDwzGCWVxvinypDgezcx8Px7w3ezHbODxkqa01TzxhX6fUrnuIxenoW7H_OwG6LyIZ6hWNEI-28eVX7I8RdbmcMEMpwuoT2ecYT8Gf9DVdV2n02CoFLswwu29c4k6M89k-EkfaFoxqoFxJRafHnrWPjyhSW" frameborder="0" allowfullscreen style="display: block; margin-left: 0; margin-right: auto; max-width: 100%;"></iframe>
     </div>
   </div>
 
   <div class="section-2 col-4 col-sm-2">
     <span class="regular sub-text">Popular links</span>
     <a href="/home" class="regular">Home</a>
     <a href="/login" class="regular">Login</a>
     <a href="/gift" class="regular">Gift</a>
     <a href="/home/#plans" class="regular">Signup</a>
     <a href="/explore-project" class="regular">Explore projects</a>
     <a href="/about-us" class="regular">About us</a>
     <a href="/blog" class="regular">Blog</a>
   </div>
 
   <div class="section-3 col-4 col-sm-3">
     <span class="regular sub-text">Helpful links</span>
     <a href="/transparency" class="regular">Transparency</a>
     <a href="/faq" class="regular">FAQ</a>
     <a href="https://www.iubenda.com/privacy-policy/28394653" class="regular iubenda-nostyle no-brand iubenda-embed" title="Privacy Policy ">Privacy Policy</a>
     <a href="https://www.iubenda.com/terms-and-conditions/28394653" class="regular iubenda-nostyle no-brand iubenda-embed" title="Terms and Conditions ">Terms and Conditions</a>
   </div>
 
   <div class="section-4 footer-desktop col-3">
     <span>Email us:</span>
     <a class="sub-text" href="mailto:connect@simplizero.com">
        <span>connect@simplizero.com</span>
      </a>
     <div class="social">
       <a href="https://www.facebook.com/simplizero/" target="_blank" rel="noopener noreferrer"><img alt="facebook logo" src="./assets/social/facebook.png"></a>
       <a href="https://www.instagram.com/simplizero/" target="_blank" rel="noopener noreferrer"><img alt="instagram logo" src="./assets/social/instagram.png"></a>
       <a href="https://www.linkedin.com/company/simplizero" target="_blank" rel="noopener noreferrer"><img alt="linkedin logo" src="./assets/social/linkedin.png"></a>
       <a href="https://twitter.com/simplizero" target="_blank" rel="noopener noreferrer"><img alt="twitter logo" src="./assets/social/twitter.png"></a>
     </div>
   </div>
 
   <div class="section-5 footer-mobile">
     <div><img class="logo" alt="simplizero logo" src="./assets/simplizero-logo/SimpliZero_white.png"/></div>
     <div>Email us:</div>
     <div><a class="sub-text" href="mailto:connect@simplizero.com">connect@simplizero.com</a></div>
     <div class="social mx-auto">
       <a href="https://www.facebook.com/simplizero/" target="_blank" rel="noopener noreferrer"><img alt="facebook logo" src="./assets/social/facebook.png"></a>
       <a href="https://www.instagram.com/simplizero/" target="_blank" rel="noopener noreferrer"><img alt="instagram logo" src="./assets/social/instagram.png"></a>
       <a href="https://www.linkedin.com/company/simplizero" target="_blank" rel="noopener noreferrer"><img alt="linkedin logo" src="./assets/social/linkedin.png"></a>
       <a href="https://twitter.com/simplizero" target="_blank" rel="noopener noreferrer"><img alt="twitter logo" src="./assets/social/twitter.png"></a>
     </div>
   </div>
 </div>
