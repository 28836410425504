<div class="banner-desktop">
  <div class="row" style="padding-top: 3%;">
    <div class="col-7 offset-values">
      <img [src]="imageSourceDesktop" class="img-fluid" alt="">
      <div class="details">
        <div class="offset">
          <!-- <p class="first">{{ offsets | number:'1.0-2' }}<small> kg</small></p> -->
          <p class="first" [innerHtml]=""></p>
          <p class="bottom">{{ "BANNER.OFFSETS" | translate }}</p>
        </div>
        <div class="supporters">
          <p class="first">{{ supporters }}</p>
          <p class="bottom">{{ "BANNER.SUPPORTERS" | translate }}</p>
        </div>
      </div>
    </div>
    <div class="col-5">
        <div class="row">
          <div class="col-12">
            <h2>{{ project && project.name }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-list>
              <mat-list-item><label for="">{{ "BANNER.LOCATION" | translate }}</label></mat-list-item>
              <mat-list-item>
                <div style="display: flex;margin-top: 10px;text-transform: capitalize">
                  <mat-icon style="margin-right: 6px;" aria-hidden="false" aria-label="Example home icon">room</mat-icon>
                 {{ project?.location?.province && project.location.province + "," }} {{ project?.location?.country }}
                </div>
              </mat-list-item>
            </mat-list>
            <mat-list class="below-tag">
              <mat-list-item><label for="">{{ "BANNER.VERIFICATION" | translate }}</label></mat-list-item>
              <mat-list-item>
                <img style="margin-right: 6px;" width="28px" [src]="project?.project_verifier?.project_verification_logo?.url" alt="">
                {{ project && project.project_verifier.project_verifier_name | titlecase }}
              </mat-list-item>
            </mat-list>
            <mat-list class="below-tag">
              <mat-list-item><label for="">{{ "BANNER.TYPE" | translate }}</label></mat-list-item>
              <mat-chip-list>
                <mat-chip [ngClass]="projectTypeAsset.color_class">
                  <img width="17px" height="24px" style="margin-right: 5px;" [src]="projectTypeAsset.logo" alt="">
                  {{ project && "PROJECT_TYPES." + project.lu_project_type.data | translate | titlecase }}
                </mat-chip>
              </mat-chip-list>
            </mat-list>
          </div>
          <div class="col-6 sdg-section">
            <mat-list>
              <label for="">{{ "BANNER.SDG" | translate }}</label>
              <div *ngFor="let image of project?.sdg_icons">
                <img width="80px" height="80px" [src]="image?.image_url?.url" alt=""><br>
              </div>
            </mat-list>
          </div>
        </div>
    </div>
  </div>
</div>

<div class="banner-mobile">
  <div class="row mobile-banner-image offset-values">
    <img [src]="imageSourceMobile" class="img-fluid" alt="">
    <div class="details">
      <div class="offset">
        <p class="first"></p>
        <p class="bottom">{{ "BANNER.OFFSETS" | translate }}</p>
      </div>
      <div class="supporters">
        <p class="first">{{ supporters }}</p>
        <p class="bottom">{{ "BANNER.SUPPORTERS" | translate }}</p>
      </div>
    </div>
  </div>
  <div class="row mobile-banner-detail">
    <h2>{{ project && project.name }}</h2>
  </div>

  <div class="row mobile-banner-detail">
    <div class="col-6">
      <mat-list>
        <mat-list-item><label for="">{{ "BANNER.LOCATION" | translate }}</label></mat-list-item>
        <mat-list-item>
          <div style="display: flex;text-transform: capitalize;">
            <mat-icon aria-hidden="false" aria-label="Example home icon">room</mat-icon>
            <label for="">{{ project?.location?.province && project.location.province + "," }} {{ project?.location?.country }}</label>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="col-6">
      <mat-list>
        <mat-list-item><label for="">{{ "BANNER.TYPE" | translate }}</label></mat-list-item>
        <mat-chip-list>
          <mat-chip [ngClass]="projectTypeAsset.color_class">
            <img width="14px" height="19px" style="margin-right: 5px;" [src]="projectTypeAsset.logo" alt="">
            {{ project && "PROJECT_TYPES." + project.lu_project_type.data | translate | titlecase }}
          </mat-chip>
        </mat-chip-list>
      </mat-list>
    </div>
  </div>

  <div class="row mobile-banner-detail">
    <div class="col-6">
      <mat-list class="below-tag">
        <mat-list-item><label for="">{{ "BANNER.VERIFICATION" | translate }}</label></mat-list-item>
        <mat-list-item>
          <img style="margin-right: 6px;" width="22px" [src]="project?.project_verifier?.project_verification_logo?.url" alt="">
          {{ project && project.project_verifier.project_verifier_name }}
        </mat-list-item>
      </mat-list>
    </div>
    <div class="col-6">
      <mat-list class="below-tag">
        <mat-list-item><label for="">{{ "BANNER.SDG" | translate }}</label></mat-list-item>
        <span *ngFor="let image of project?.sdg_icons">
          <img width="50px" [src]="image?.image_url?.url" alt=""/>
        </span>
      </mat-list>
    </div>
  </div>
</div>
