import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  private _sendProjectToChild = new Subject<object>();
  private _sendProjectSummaryToChild = new Subject<object>();
  BROWSER_LANG;
  OFFSET_API_URL = environment.OFFSET_API_URL;
  GEO_SERVICE_URL= 'https://pro.ip-api.com/json?key=y2xiKHaFKwFy6pP';

  constructor(private http: HttpClient, public translate: TranslateService) {
    this.BROWSER_LANG = translate.currentLang;
    translate.onLangChange.subscribe(({ lang }) => {
      this.BROWSER_LANG = lang;
    });
  }

  getProject(projectId, lanCode = null) {
    return this.http.get(`${this.OFFSET_API_URL}/offset/project/${projectId}`, {
      params: {
        content: "full",
        lang_code: lanCode ? lanCode : this.BROWSER_LANG,
      },
    });
  }

  getProjectSummary(projectId) {
    return this.http.get(`${this.OFFSET_API_URL}/offset/summary?filter_by_offset_project=${projectId}&measures=offset_value,amount,supporter`);
  }

  getCountryCode() {
    console.log("urls===", this.GEO_SERVICE_URL);
    return this.http.get(this.GEO_SERVICE_URL);       
  }
  

  getProjectFromParent = this._sendProjectToChild.asObservable();
  getProjectSummaryFromParent = this._sendProjectSummaryToChild.asObservable();

  sendProject(project: object) {
    this._sendProjectToChild.next(project);
  }
  sendProjectSummary(summary: object) {
    this._sendProjectSummaryToChild.next(summary);
  }
}
