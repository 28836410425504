import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';

@Component({
  selector: 'app-project-updates',
  templateUrl: './project-updates.component.html',
  styleUrls: ['./project-updates.component.scss']
})
export class ProjectUpdatesComponent implements OnInit {

  updates: any;
  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.projectService.getProjectFromParent.subscribe( project => {
      this.updates = project['project_updates'];
    })
  }

}
