import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectV2Component } from './project-v2/project-v2.component';
import { ProjectComponent } from './project/project.component';


const routes: Routes = [
  { path: '', component: ProjectV2Component },
  { path: 'project/:projectId', component: ProjectV2Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
