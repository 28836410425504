import { AfterViewInit, Component, OnChanges, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ProjectService } from "../services/project.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-v2',
  templateUrl: './project-v2.component.html',
  styleUrls: ['./project-v2.component.scss']
})
export class ProjectV2Component implements OnInit, AfterViewInit {

  project: any = {};

  lanCode: string;

  projectId;

  carbonRegistryUrl = environment.CARBON_REGISTRY_URL;

  videolightboxinput;

  REFORESTATION = "reforestation";
  SOCIAL = "social";
  RENEWABLE = "renewable";

  carbonVerifierAltText;

  @ViewChild("mapMobile") mapMobileElement: any;

  @ViewChild("mapDesktop") mapDesktopElement: any;

  @ViewChildren("learnmore") learnMore: any;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    let projectId: string;
    projectId = this.route.snapshot.queryParamMap.get("projectId");
    if (!projectId) {
      projectId = this.route.snapshot.paramMap.get("projectId");
    }
    this.projectId = projectId;
    this.sendDataToComponent('gs-carousel', 'projectid', projectId);
    this.setLangCode();
    this.getProject(projectId);
    this.carbonRegistryUrl = `${this.carbonRegistryUrl}?project_id=${this.projectId}&lang_code=${this.lanCode || 'en'}`
  }

  ngAfterViewInit() {
    this.sendDataToComponent('gs-offset-supporter', 'projectid', this.projectId);
  }

  sendDataToComponent(
    componentTag: string,
    attributeName: string,
    attributeValue: string
  ) {
    const elements = Array.from(document.getElementsByTagName(componentTag));
    elements &&
      elements.length > 0 &&
      elements.map((element) => {
        element.setAttribute(attributeName, attributeValue);
      });
  };

  setLangCode() {
    this.lanCode = this.route.snapshot.queryParamMap.get("lanCode");
    if (this.lanCode) {
      this.translate.use(this.lanCode);
      this.sendDataToComponent('gs-offset-supporter', 'lang', this.lanCode);
    }
  }

  getProjectAltText(image_obj, text) {
    return image_obj && image_obj.alt_text
      ? image_obj.alt_text
      : image_obj && image_obj.asset_lib_name
      ? image_obj.asset_lib_name
      : text;
  }

  getTypeBackgroundImageAltText(type)  {
    switch (type) {
      case this.REFORESTATION:
        return "reforestation project";
      case this.SOCIAL:
        return "social project";
      case this.RENEWABLE:
        return "renewable project";
      default:
        return "reforestation project";
    }
  };

  async getProject(projectId) {
    if (projectId) {
      this.projectService.getProject(projectId, this.lanCode).subscribe((project: any) => {
        this.project = project;
        this.carbonVerifierAltText = this.getProjectAltText(project.project_verifier.project_verification_logo, 'carbon offset verifier logo')
        setTimeout(() => {
          this.sendDataToComponent('gs-handle-lightbox-scroll-click', 'inputforlightbox', `{"videosource": "${project?.video}", "id": "0"}`);
        }, 300);
        this.initializeMap();
      })
    }
  }

  initializeMap() {
    if (this.project && this.project.geolocation) {
        const { lat, long } = this.project.geolocation;
        const googleMap = document.createElement("gs-google-map");
        googleMap.setAttribute("zoom", "7");
        googleMap.setAttribute("isdesktop", "true");
        let projectLocations = JSON.stringify([
          {
            lat: parseFloat(lat),
            lng: parseFloat(long),
          },
        ]);
        googleMap.setAttribute("projectlocations", projectLocations);
        const googleMap1 = document.createElement("gs-google-map");
        googleMap1.setAttribute("zoom", "7");
        googleMap1.setAttribute("ismobile", "true");
        googleMap1.setAttribute("projectlocations", projectLocations);
        this.mapDesktopElement.nativeElement.appendChild(googleMap);
        this.mapMobileElement.nativeElement.appendChild(googleMap1);
    }
  }
}
