import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from '../environments/environment'

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "offset-portal";
  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
    translate.addLangs(["en", "fr", "de", "es", "jap"]);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|de|es|jap/) ? browserLang : "en");
  }

  ngOnInit() {
    this.loadGTM();
    this.loadWidget();
  }

  loadGTM(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-${environment.GTM_TAG}');`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }

  loadWidget() {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = `${environment.WIDGET_URL}/offset-widgets/offset-widgets.esm.js`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }
}
