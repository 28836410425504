import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {ProjectService} from "../services/project.service";
import {ActivatedRoute} from "@angular/router";

const {CARBON_REGISTRY_URL} = environment;

@Component({
  selector: 'app-project-registry',
  templateUrl: './project-registry.component.html',
  styleUrls: ['./project-registry.component.scss']
})
export class ProjectRegistryComponent implements OnInit {
  carbonRegistryUrl: string = CARBON_REGISTRY_URL;
  projectId: number;
  langCode: string;
  registryUrl: string = "";

  constructor(private projectService: ProjectService,private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.langCode = this.route.snapshot.queryParamMap.get("lanCode") !== null ? this.route.snapshot.queryParamMap.get("lanCode") : 'en';
    this.projectService.getProjectFromParent.subscribe(project => {
      this.projectId = +project['id'];
      this.registryUrl = this.generateUrl();
    });
  }

  generateUrl(){
    return `${this.carbonRegistryUrl}?project_id=${this.projectId}&lang_code=${this.langCode}`
  }

}
