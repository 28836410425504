<div class="container" style="padding-bottom: 5%;">
   <div *ngFor="let project_update of updates; let i = index">
      <div class="card mt-5">
         <img class="card-img-top desktop" [src]="project_update.image_desktop.url" [alt]="project_update.image_desktop.asset_lib_name">
         <img class="card-img-top mobile" [src]="project_update.image_mobile.url" [alt]="project_update.image_mobile.asset_lib_name">
         <div class="card-body row">
         <p class="card-text col-8 col-sm-8 child-details" [innerHTML]="project_update.updates"></p>
         <p class="card-text col-4 col-sm-4 date-box">{{ project_update.update_time | date:'LLL d' | uppercase }}</p>
         </div>
      </div>
   </div>
</div>
