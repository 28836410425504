import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from '../services/project.service';

const LOCALE_LIST = ["af-ZA", "am-ET", "ar-DZ", "ar-LY", "ar-MA", "arn-CL", "ar-TN", "az-Cyrl-AZ", "az-Latn-AZ", "ba-RU", "be-BY", "bg-BG", "bo-CN", "fr-FR", "bs-Cyrl-BA", "bs-Latn-BA", "ca-ES", "co-FR", "cs-CZ", "cy-GB", "da-DK", "de-AT", "de-CH", "de-DE", "de-LI", "de-LU", "dsb-DE", "dv-MV", "el-GR", "en-029", "en-AU", "en-BZ", "en-CA", "en-GB", "en-IE", "en-IN", "en-JM", "en-MY", "en-NZ", "en-PH", "en-SG", "en-TT", "en-US", "en-ZA", "en-ZW", "es-AR", "es-BO", "es-CL", "es-CO", "es-CR", "es-DO", "es-EC", "es-ES", "es-GT", "es-HN", "es-MX", "es-NI", "es-PA", "es-PE", "es-PR", "es-PY", "es-SV", "es-US", "es-UY", "es-VE", "et-EE", "eu-ES", "fi-FI", "fil-PH", "fo-FO", "fr-BE", "fr-CA", "fr-CH", "fr-FR", "fr-LU", "fr-MC", "fy-NL", "ga-IE", "gd-GB", "gl-ES", "gsw-FR", "gu-IN", "ha-Latn-NG", "he-IL", "hi-IN", "hr-BA", "hr-HR", "hsb-DE", "hu-HU", "hy-AM", "id-ID", "ig-NG", "ii-CN", "is-IS", "it-CH", "it-IT", "iu-Cans-CA", "iu-Latn-CA", "ja-JP", "ka-GE", "kk-KZ", "kl-GL", "km-KH", "kn-IN", "kok-IN", "ko-KR", "ky-KG", "lb-LU", "lo-LA", "lt-LT", "lv-LV", "mi-NZ", "mk-MK", "ml-IN", "mn-MN", "mn-Mong-CN", "moh-CA", "ms-BN", "ms-MY", "mt-MT", "nb-NO", "nl-BE", "nl-NL", "nn-NO", "nso-ZA", "oc-FR", "or-IN", "pa-IN", "pl-PL", "pt-BR", "pt-PT", "qut-GT", "quz-BO", "quz-EC", "quz-PE", "rm-CH", "ro-RO", "ru-RU", "rw-RW", "sah-RU", "sa-IN", "se-FI", "se-NO", "se-SE", "si-LK", "sk-SK", "sl-SI", "sma-NO", "sma-SE", "smj-NO", "smj-SE", "smn-FI", "sms-FI", "sq-AL", "sr-Cyrl-BA", "sr-Cyrl-CS", "sr-Cyrl-ME", "sr-Cyrl-RS", "sr-Latn-BA", "sr-Latn-CS", "sr-Latn-ME", "sr-Latn-RS", "sv-FI", "sv-SE", "sw-KE", "syr-SY", "ta-IN", "te-IN", "tg-Cyrl-TJ", "th-TH", "tk-TM", "tn-ZA", "tr-TR", "tt-RU", "tzm-Latn-DZ", "ug-CN", "uk-UA", "ur-PK", "uz-Cyrl-UZ", "uz-Latn-UZ", "vi-VN", "wo-SN", "xh-ZA", "yo-NG", "zh-CN", "zh-HK", "zh-MO", "zh-SG", "zh-TW", "zu-ZA", ];

const TYPE_COLOR_LOGO = [
  {
    type: "reforestation",
    color_class: "reforestation-background",
    logo: "../assets/Reforestation.png"
  },
  {
    type: "renewable",
    color_class: "renewable-background",
    logo: "../assets/Renewable.png"
  },
  {
    type: "social",
    color_class: "social-background",
    logo: "../assets/social.png"
  }
]

@Component({
  selector: 'app-project-banner',
  templateUrl: './project-banner.component.html',
  styleUrls: ['./project-banner.component.scss']
})
export class ProjectBannerComponent implements OnInit {

  project: any
  imageSourceDesktop = ""
  imageSourceMobile = ""
  supporters = '';
  offsets = '';
  locale: string;
  projectTypeAsset = {
    logo: "../assets/forest_web.png",
    color_class: "reforestation-background"
  }
  constructor(private projectService: ProjectService) { }

  async ngOnInit() {
    await Promise.all([
      this.projectService.getProjectFromParent.subscribe(project => {
        this.project = project;
        console.log(project);
        this.imageSourceDesktop = this.project.image_desktop.url;
        this.imageSourceMobile = this.project.image_mobile.url;

        TYPE_COLOR_LOGO.map( i => {
          if(i.type.toLocaleLowerCase() === project['lu_project_type'].data.toLocaleLowerCase()) {
            this.projectTypeAsset.logo = i.logo;
            this.projectTypeAsset.color_class = i.color_class;
          }
        })
      }),
      // this.projectService.getProjectSummaryFromParent.subscribe(summary => {
      //   if (summary) {
      //     this.supporters = summary['supporters'];
      //     this.offsets = summary['offsets'][0].value;
      //   }
      // })
    ]);
    this.locale = await this.getLocale();
  }

  async getLocale() {
    const locale = localStorage.getItem("GS_LOCALE");
    
    if (locale !== null) {
      return locale;
    }
    this.projectService.getCountryCode().subscribe(response => {
      let countryCode = response['countryCode'];
      if (countryCode) {
        const temp = LOCALE_LIST.filter((locale) => locale.includes(countryCode));
        if (temp.length > 0) {
          localStorage.setItem("GS_LOCALE", temp[0]);
          return temp[0];
        } else {
          return "en-CA";
        }
      } else {
        return "en-CA";
      }
    });
  }

  getWeight(valueInKg, locale = "en-CA") {
    valueInKg = parseFloat(valueInKg).toFixed(1);
    if (locale && locale.includes("US")) {
      valueInKg = 2.20462 * valueInKg;
    }
    valueInKg = parseFloat(valueInKg).toFixed(1);
    return locale && locale.includes("US")
      ? `${new Number(valueInKg).toLocaleString(locale ? locale : 'en-CA')}${valueInKg <= 1 ? "<small> lb</small>" : "<small> lbs</small>"}`
      : ` ${new Number(valueInKg).toLocaleString(locale ? locale : 'en-CA')}${valueInKg <= 1 ? "<small> kg</small>" : "<small> kg</small>"}`;
  }
}