import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { ProjectUpdatesComponent } from './project-updates/project-updates.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectRegistryComponent } from './project-registry/project-registry.component';
import { ProjectHeaderComponent } from './project-header/project-header.component';
import { ProjectFooterComponent } from './project-footer/project-footer.component';
import { ProjectBannerComponent } from './project-banner/project-banner.component';
import { ProjectComponent } from './project/project.component';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ProjectV2Component } from './project-v2/project-v2.component';
import { TabsComponent } from './project-v2/tabs/tabs.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    ProjectOverviewComponent,
    ProjectUpdatesComponent,
    ProjectDetailsComponent,
    ProjectRegistryComponent,
    ProjectHeaderComponent,
    ProjectFooterComponent,
    ProjectBannerComponent,
    ProjectComponent,
    ProjectV2Component,
    TabsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatChipsModule,
    MatListModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
