<div class="container overview-section">
   <div class="row">
      <div class="col-12">
         <h1>{{ "OVERVIEW.ABOUT" | translate }}</h1>
      </div>
      <div class="col-12">
         <div class="child-details" [innerHTML]="overview && overview.about_project"></div>
      </div>
   </div>

   <hr>

   <div class="row">
      <div class="col-12">
         <h1>{{ "OVERVIEW.GLANCE" | translate }}</h1>
      </div>
      <div class="col-12">
         <div class="child-details" [innerHTML]="overview && overview.at_a_glance"></div>
      </div>
   </div>

   <hr>

   <div class="row">
      <div class="col-12">
         <h1>{{ "OVERVIEW.BENEFITS" | translate }}</h1>
      </div>
      <div class="col-md-6 col-sm-12">
         <h6>{{ "OVERVIEW.SOCIAL" | translate }}</h6>
         <div class="child-details" [innerHTML]="overview && overview.benefits_social"></div>
      </div>
      <div class="col-md-6 col-sm-12">
         <h6>{{ "OVERVIEW.ENVIRONMENT" | translate }}</h6>
         <div class="child-details" [innerHTML]="overview && overview.benefits_environmental"></div>
      </div>
   </div>

   <hr>

   <div class="row">
      <div class="col-12">
         <h1>{{ "OVERVIEW.HOW_IT_WORKS" | translate }}</h1>
      </div>
      <div class="col-12">
         <div class="child-details" [innerHTML]="overview && overview.how_it_works"></div>
      </div>
   </div>
</div>
