// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  OFFSET_API_URL: 'https://offset-service-staging.greenstory.ca/api/v1',
  WIDGET_URL: 'https://gsstatic-staging.greenstory.ca',
  GOOGLE_MAP_EMBED_API_KEY: 'AIzaSyCtwUdkvX559WrbigQtRy9pHXsA5BuOJtI',
  GOOGLE_MAP_ID: '4f0ba9ab2db6d0e4',
  GTM_TAG: 'TQVQPDC',
  CARBON_REGISTRY_URL: 'https://staging.simplizero.com/carbon-registry/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
