<div class="tabs">
    <div class="tab regular">
        <gs-handle-lightbox-scroll-click scrollid="about">
            About
        </gs-handle-lightbox-scroll-click>
    </div>
    <div class="tab regular">
        <gs-handle-lightbox-scroll-click scrollid="benefits">
            Benefits
        </gs-handle-lightbox-scroll-click>
    </div>
    <div class="tab regular">
        <gs-handle-lightbox-scroll-click scrollid="registry">
            Registry
        </gs-handle-lightbox-scroll-click>
    </div>
</div>